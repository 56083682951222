import React, { useEffect, useState } from "react";

import "./style.css"
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';




import { format } from "date-fns";



function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}`,
  };
}
function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}



const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [open, setIsOpen] = useState(false);
  const toggleForm = () => setIsOpen(!open);


  useEffect(() => {
    const getPosts = async () => {
      const resp = await fetch(
        "https://general-assignment.mtopak.workers.dev/posts"
      );
      const postsResp = await resp.json();

      setPosts(postsResp);
    };

    getPosts();
  }, []);


  const sortScore = () => {
    const postsSorted = [...posts].sort((a, b) => {
      if (a.score < b.score) return 1;
      if (a.score > b.score) return -1;
      return 0;
    });
    setPosts(postsSorted)
  };

  const sortID = () => {
    const postsSorted = [...posts].sort((a, b) => {
      if (a.postId < b.postId) return -1;
      if (a.postId > b.postId) return 1;
      return 0;
    });
    setPosts(postsSorted)
  };



  const handleUpVote = async event => {
    event.preventDefault();
    let index = (event.target.value)
    const postId = posts[index].postId


    const url = 'https://general-assignment.mtopak.workers.dev/vote'
    let score = 1
    const body = JSON.stringify({ postId, score });

    const requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: body
    };
    const response = await fetch(url, requestOptions)
    if (response.status === 200) {
      let items = [...posts];
      let item = { ...posts[index] };
      item['score'] += 1
      items[index] = item
      setPosts(items)
    }

  };

  const handleDownVote = async event => {
    event.preventDefault();
    let index = (event.target.value)
    const postId = posts[index].postId


    const url = 'https://general-assignment.mtopak.workers.dev/vote'
    let score = -1
    const body = JSON.stringify({ postId, score });

    const requestOptions = {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: body
    };
    const response = await fetch(url, requestOptions)
    if (response.status === 200) {
      let items = [...posts];
      let item = { ...posts[index] };
      item['score'] -= 1
      items[index] = item
      setPosts(items)
    }

  };

  function BasicForm() {
    const [username, setUserName] = useState('')
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')






    const handleUserNameChange = event => {
      setUserName(event.target.value)
    };
    const handleTitleChange = event => {
      setTitle(event.target.value)
    };
    const handleContentChange = event => {
      setContent(event.target.value)
    };



    const handleSubmit = async event => {
      event.preventDefault();



      const url = 'https://general-assignment.mtopak.workers.dev/posts'

      const body = JSON.stringify({ username, title, content });

      const requestOptions = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json"
        },
        body: body
      };
      const response = await fetch(url, requestOptions)
      if (response.status === 200) {
        const postId = response.headers.get('postid');
        const time = parseInt(response.headers.get('posttime'));
        const score = 0;
        const json = { username, title, content, time, postId, score };
        setPosts(posts.concat(json));
      }


    };


    return (
      <form className="addForm" onSubmit={handleSubmit}>
        <div>

          <br />
          <input
            type="text"
            name="username"
            placeholder="Username"
            onChange={handleUserNameChange}
            value={username}
          />

          <br />
          <input
            type="text"
            name="title"
            placeholder="Title"
            onChange={handleTitleChange}
            value={title}
          />
          <br />
          <textarea
            rows="4"
            cols="27"
            name="content"
            placeholder="Content"
            onChange={handleContentChange}
            value={content}
          />

        </div>
        <button type="submit">
          Submit
        </button>
      </form>
    )
  }





  return (
    <div>

      <Grid container justifyContent='space-around'>



        {posts.map((post, index) => (


          <Card key={index} align='center' variant="outlined" sx={{ m: 0.5, p: 0.5 }}>

            <Grid container alignItems='center'>

              <Grid style={{maxWidth:400}} item sx={{ m: 1, borderRight: 1, pr: 2 }}>
                <Avatar  {...stringAvatar(post.username)} />
                <p style={{overflow:'hidden', textOverflow:'ellipsis'}}>{post.username}</p>
                <p>{format(post.time, "MMMM do, yyyy")}</p>
                <p>{format(post.time, " H:mma")}</p>
                <p>#{post.postId}</p>
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                  <Button value={index} onClick={handleUpVote} style={{ backgroundColor: 'green' }}>+</Button>
                  <Button value={index} onClick={handleDownVote}>-</Button>
                  <Button style={{ backgroundColor: 'gray' }}>{post.score}</Button>
                </ButtonGroup>

              </Grid>
              <Grid style={{maxWidth:400}} item sx={{ pr: 2, pl: 2 }} justifyContent='center'>
                <h2>{post.title}</h2>
                <p>{post.content}</p>
              </Grid>
            </Grid>


          </Card>

        ))}
      </Grid>
      {open && <BasicForm />}
      <button className="plus radius" onClick={toggleForm}></button>
      <ButtonGroup style={{position:'fixed', bottom:'50px', left:'50px'}} variant="contained" aria-label="outlined primary button group">
        <Button style={{backgroundColor:'green'}}>SORT BY</Button>
        <Button onClick={sortScore} >Score</Button>
        <Button onClick={sortID}>ID</Button>
      </ButtonGroup>

    </div>
  );
};

export default Posts;