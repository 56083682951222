import Posts from "./posts";

const Home = () => {
  return (
    <div>
        <Posts />
    </div>
  );
};

export default Home;