import { Router } from "@reach/router";

import Home from './components/home'

function App() {
  return (
    <Router>
      <Home path="/"  /> 
    </Router>
  );
}

export default App;